/* eslint-disable react/jsx-key */
import React from "react"

import imgPortada from "@components/pageFunctionalities/pageChatbotsEmpresas/image/imgStart.png"
import imgSilder1 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/imgStart1.png"
import imgSilder2 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/imgStart2.png"

//iconos de funcion
import tododia from "@components/pageTicker/pageSolucionOmnicanal/image/card1.png"
import mensaje from "@components/pageTicker/pageSolucionOmnicanal/image/card2.png"
import cobranza from "@components/pageTicker/pageSolucionOmnicanal/image/card3.png"
import automatizacion from "@components/pageTicker/pageSolucionOmnicanal/image/card4.png"


// logos testimonios
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CafaeTes from "@components/sharedComponents/testimonies/images/logos/cafae.svg"

//foto testimonio desktop
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cafaeDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cafae.png"

//foto testimonio desktop
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cafaeMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cafae.png"


//imagenes estadisticas
import estadistica from "@components/pageFunctionalities/pageChatbotsEmpresas/image/lupa.svg"
import reloj from "@components/pageFunctionalities/pageChatbotsEmpresas/image/apoyar.svg"
import cono from "@components/pageFunctionalities/pageChatbotsEmpresas/image/libre.svg"
import robot from "@components/pageFunctionalities/pageChatbotsEmpresas/image/chatbot.svg"

import card1 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/card1.png"
import card2 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/card2.png"
import card3 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/card3.png"
import card4 from "@components/pageFunctionalities/pageChatbotsEmpresas/image/card4.png"


const title = (
    <p className="guide__title">
Automatiza tu atención y vende más con nuestro software de chatbot

    </p>
)

const textDescription = (
    <p>
Crea chatbots sin códigos y de la manera más sencilla para los principales canales de atención de tu empresa como WhatsApp, Instagram, Facebook y Webchat.
    </p>
)


const data = {
    start: {
        title: title,
        classNameTitle: "text-title-introone",
        textbody: textDescription,
        image: imgPortada,
        classNameIntro: "frame-introatencion",
        progress: "progressatencion",
        imageSilder: [imgSilder2, imgSilder1],
        textbutton: "Quiero una asesoría",
    },
    useCases: {
        title: <>Lo que hará Beex con tu Contact Center</>,
        description: "",
      },
    modules: [
        {
          title: "Unifica los canales preferidos",
          img: tododia,
          classtitle: "card-title-inbox",
          status: "Disponible",
          class: "texto-espacio",
          info:
            "Integra los canales más importantes para tus clientes como WhatsApp, Facebook e Instagram.",
        },
        {
          title: "Permite todas las integraciones",
          classtitle: "card-title-inbox",
          img: mensaje,
          info:
            "Su estructura de APIs permite integrarse a las herramientas que utilices, como tu CRM.",
          status: "Disponible",
          class: "texto-espacio",
        },
        {
          title: "Marcadores Inteligentes",
          classtitle: "card-title-inbox",
          img: cobranza,
          info:
            "Tanto marcadores predictivos como progresivos, ambos trabajan con Machine Learning, lo que te permite automatizar tus campañas en pocos minutos.",
          status: "Disponible",
          class: "texto-espacio",
        },
        {
          title: "Enruta y supervisa en vivo",
          classtitle: "card-title-inbox",
          img: automatizacion,
          info:
            "Crea enrutamientos inteligentes de los tickets de atención y supervisa en tiempo real a tus agentes.",
          status: "Disponible",
          class: "texto-espacio",
        },
      ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: KontigoTes,
            title:
                "Con WhatsApp API, mejoramos nuestro tiempo de primera respuesta en <1 minuto",
            textbody:
                "Kontigo, fintech respaldada por Mastercard, es capaz de cerrar el 98% de sus casos en un primer contacto gracias a sus chatbots integrados a la API de WhatsApp Business.",
            name: "Analucía Siguas Velásquez",
            post: "Gerente de Marketing y Producto",
            link: "/casos-de-exito/kontigo/",
            imgDesktop: kontigoDesktop,
            imgMobile: kontigoMobile,
            metrics: [
                {
                    metric: "98%",
                    descripcion: "casos cerrados en primer contacto",
                    color: "#FFB800",
                },
                {
                    metric: "<1 minuto",
                    descripcion: "tiempo de primera respuesta",
                    color: "#5831D8",
                },
                {
                    metric: "x3",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: AstaraTes,
            title:
                "Con envíos masivos de WhatsApp logramos reducir el esfuerzo de remarketing en 93%",
            textbody:
                "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
            name: "Jonny Castillo",
            post: " Jefe Comercial",
            link: "/casos-de-exito/astara/",
            imgDesktop: astaraDesktop,
            imgMobile: astaraMobile,
            metrics: [
                {
                    metric: "x3",
                    descripcion: "aumentó sus ventas digitales",
                    color: "#FFB800",
                },
                {
                    metric: "93%",
                    descripcion: "redujo su esfuerzo para remarketing",
                    color: "#5831D8",
                },
                {
                    metric: "80%",
                    descripcion: "mejoró contactabilidad con leads",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: AvivaTes,
            title:
                "Con los workflows de WhatsApp mejoramos en +30% el tiempo de agenda de citas",
            textbody:
                "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
            name: "Luis Ordinola",
            post: "Coordinador de mejora de procesos",
            link: "/casos-de-exito/clinica-aviva/",
            imgDesktop: avivaDesktop,
            imgMobile: avivaMobile,
            metrics: [
                {
                    metric: "+30%",
                    descripcion: "mejoró el tiempo de agenda de citas",
                    color: "#FFB800",
                },
                {
                    metric: "+20%",
                    descripcion: "ahora representa WhatsApp como canal para agendar citas",
                    color: "#5831D8",
                },
            ],
        },
        {
            image: SavarTes,
            title:
                "Con WhatsApp Business API hemos reducido en 30% las consultas repetitivas de clientes",
            textbody:
                "Savar, empresa líder en logística, asegura que sus clientes reciban actualizaciones constantes y oportunas por WhatsApp, logrando mejorar sus índices de satisfacción.",
            name: "Dinkar Rios Macedo",
            post: "Jefe de Sistemas",
            link: "/casos-de-exito/savar/",
            imgDesktop: savarDesktop,
            imgMobile: savarMobile,
            metrics: [
                {
                    metric: "+20%",
                    descripcion: "aumentó la satisfacción de sus clientes",
                    color: "#FFB800",
                },
                {
                    metric: "+30%",
                    descripcion: "se redujeron las consultas repetitivas",
                    color: "#5831D8",
                },
                {
                    metric: "25%",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: CafaeTes,
            title:
                "Con los chatbots de WhatsApp, cerramos +60% de consultas sin necesidad de agentes",
            textbody: "CAFAE-SE, institución de alcance nacional para el sector educación público, gestiona sus miles de interacciones diarias por WhatsApp, con chatbots y uso multiagente.",
            name: "Johans Herencia",
            post: "Responsable de Marketing Digital",
            link: "/casos-de-exito/cafae-se/",
            imgDesktop: cafaeDesktop,
            imgMobile: cafaeMobile,
            metrics: [
                {
                    metric: "60%",
                    descripcion: "de casos cerrados por chatbots",
                    color: "#FFB800",
                },
                {
                    metric: "70%",
                    descripcion: "de reducción de tiempo de respuesta",
                    color: "#5831D8",
                },
            ],
        },
        {
            title: "conoce",
            metrics: [
            ],
        },
    ],

    useScore: [
        {
            icon: estadistica,
            title: "Supervisión de chats en tiempo real",
            text:
              "Con nuestra plataforma podrás analizar en tiempo real cómo trabajan tus bots. Así, podrás aplicar los cambios necesarios para una correcta atención.",
          },
          {
            icon: reloj,
            title: "Ayuda personalizada de nuestro equipo",
            text:
              "Te acompañamos siempre en la creación de tus bots, te ofrecemos asesorías gratuitas para que los puedas configurar de acuerdo a tus requerimientos.",
          },
          {
            icon: cono,
            title: "Acceso gratis a la API de WhatsApp Business",
            text:
              "Esta herramienta es la única que te permite crear chatbots en WhatsApp. Por eso, podrás acceder a ella sin costo alguno y solo pagar por consumo.",
          },
          {
            icon: robot,
            title: "Chatbots sin códigos",
            text:
              "No es necesario que tu equipo tenga conocimientos de programación para crearlos. Solo debes preocuparte en crear tus flujos de mensaje.",
          },
    ],
    contacts: {     
        title: " Crea experiencias conversacionales automáticas en tus canales de atención",
        textbody:
            "Nuestros asesores estarán dispuestos para que puedas tener tu propio chatbot hoy mismo.",
        btn: "Agenda una reunión gratis",
        href: "/hablemos/",
    },
    cardsText: {
        title: <>¿Qué puedes lograr con nuestro software de chatbot?</>,
        description: "",
      },
      cards: [card1, card2, card3, card4],
}

export default data
